"use client";

import { FC, PropsWithChildren } from "react";
import { ReCaptchaProvider } from "next-recaptcha-v3";

import { GOOGLE_RECAPTCHA_SITE_KEY } from "@Shared/config";
import { useSetScrollbarWidth, useVUnits } from "@Shared/hooks";

import { GSAPRegister } from "./GSAPRegister";

export const ClientWrapper: FC<PropsWithChildren> = ({ children }) => {
    useSetScrollbarWidth();
    useVUnits();

    return (
        <>
            <GSAPRegister />

            <ReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY}>{children}</ReCaptchaProvider>
        </>
    );
};
