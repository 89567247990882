import { cache } from "react";

import { FORMS, MODALS } from "@Shared/config";

import { client } from "./client";

export const getCommonData = cache(async () => {
    const commonParallelQueries = await Promise.all([
        client.common.getCommonData(),
        client.common.getHeaderData(),
        client.common.getNavigationData(),
        client.common.getFooterData(),
        client.modals.getModalData(MODALS.contactUs),
        client.forms.getFormData(FORMS.contactUs),
        client.socials.getSocialsData(),
    ]);

    return {
        common: commonParallelQueries[0],
        header: commonParallelQueries[1],
        navigation: commonParallelQueries[2],
        footer: commonParallelQueries[3],
        modals: {
            contactUs: commonParallelQueries[4],
        },
        forms: {
            contactUs: commonParallelQueries[5],
        },
        socials: commonParallelQueries[6],
    };
});

export const getPageArticle = cache(async () => client.pages.getPageArticleData());

export const getPageArticles = cache(async () => client.pages.getPageArticlesData());

export const getPageHome = cache(async () => client.pages.getPageHomeData());

export const getPageNotFound = cache(async () => client.pages.getPageNotFoundData());

export const getPageProject = cache(async () => client.pages.getPageProjectData());

export const getPageProjects = cache(async () => client.pages.getPageProjectsData());

export const getPageSimple = cache(async (slug: string, params?: object) =>
    client.pages.getPageSimpleData(slug, params)
);

export const getAllPagesSimple = cache(async (params?: object) => client.pages.getAllPagesSimpleData(params));

export const getArticles = cache(async (params?: object) => client.articles.getArticlesData(params));

export const getArticle = cache(async (slug: string, params?: object) => client.articles.getArticleData(slug, params));

export const getProjects = cache(async (params?: object) => client.projects.getProjectsData(params));

export const getProject = cache(async (slug: string, params?: object) => client.projects.getProjectData(slug, params));

export const getProjectsCategories = cache(async (params?: object) =>
    client.projects.getProjectsCategoriesData(params)
);

export const getReviews = cache(async (params?: object) => client.reviews.getReviewsData(params));

export const getModal = cache(async (slug: string, params?: object) => client.modals.getModalData(slug, params));

export const getForm = cache(async (slug: string, params?: object) => client.forms.getFormData(slug, params));

export const sendForm = cache(async (slug: string, data: object, recaptchaToken?: string) =>
    client.forms.sendFormData(slug, data, recaptchaToken)
);

export const sendTelegramMessage = cache(async (message: string) => client.telegram.sendTelegramMessage(message));
